import {
  AccessibilityNew,
  CheckCircle,
  CreditScore,
  Devices,
  Handyman,
  InvertColors,
  LocalPhone,
  LocationOn,
  LockPerson,
  Mail,
} from "@mui/icons-material";
import SecurityIcon from "@mui/icons-material/Security";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import CollectionsIcon from "@mui/icons-material/Collections";
import FeedbackIcon from "@mui/icons-material/Feedback";
import PolicyIcon from "@mui/icons-material/Policy";
import {
  BrianImg,
  DoreenImg,
  IsaacImg,
  JulietImg,
  OshinImg,
  SamImg,
  ShamilahImg,
} from "../assets/img/team/TeamImages";
import {
  AccountingSVG,
  BellSVG,
  BrandSVG,
  CardSVG,
  CoinsSVG,
  DesignSVG,
  HelpSVG,
  MissionSVG,
  SaveSVG,
  SmartSVG,
  StatsSVG,
  UsersSVG,
} from "../assets/svg/Svgs";
import { Tag } from "antd";
import { HighlightSpan, RowWrapper } from "../components/styled/Styled";
import { Ills1 } from "../assets/img/Images";

// WHY OPTIONS
export const whyData = [
  {
    Icon: InvertColors,
    title: "Timeless Aesthetics",
    desc:
      "Experience the perfect blend of beauty and simplicity with our intuitive interface. Effortlessly manage everything a single, unified platform, ensuring seamless navigation and ease of use.",
  },

  {
    Icon: Handyman,
    title: "Intuitive Customization",
    desc:
      "Our systems offer an ideal balance of flexibility and power. With our robust admin settings, you are in complete control and can tailor the system to your preferences and needs.",
  },
  {
    Icon: LockPerson,
    title: "Reliable and Secure",
    desc:
      "Rest assured that all your data and information are securely stored on AWS, renowned as one of the world's most trusted and secure cloud server.",
  },

  {
    Icon: AccessibilityNew,
    title: "Dedicated to Accessibility",
    desc:
      "We prioritize your needs with utmost seriousness. Feel free to visit us or reach out to us via phone or email at any time during our business hours. We are always here to assist you.",
  },
];

// FAQS
export const faqData = [
  {
    label: "How secure is our data on your platform?",
    children:
      "Your data is stored on AWS, one of the most secure cloud servers in the world. AWS offers security features such as Identity and access management (IAM), Single Sign On (SSO), SSL and TLS provisioning , and Web application firewall (WAF) to name a few. Contact us if you want to know more about how we protect your data.",
  },
  {
    label: "Does Zimba’s platform require internet to operate?",
    children:
      "Yes, Zimba is cloud based and therefore requires internet, but we have built the platform to run on minimal bandwidth.",
  },

  {
    label:
      "Will Zimba train us on how to use the platform and how will we transfer our data from our old system?",
    children:
      "Yes, we have an onboarding team that will train you and show you how to transfer you old data.",
  },
  {
    label: "How much will it cost us to use Zimba?",
    children: (
      <>
        Our pricing depends on the size of your institution; but we are one of
        the most affordable on the market. We charge one consistent price every
        month and do not require a large upfront payment like competitors.
      </>
    ),
  },

  {
    label: (
      <p>
        Can Zimba be accessed on phones or tablets?{" "}
        <Tag color="green">Beta</Tag>
      </p>
    ),
    children: (
      <>
        We do not yet have a downloadable application, but yes, you can access
        Zimba on your smart phone or tablet as a web application.
      </>
    ),
  },

  {
    label:
      "How do we contact you if we are having issues with the Zimba platform?",
    children: (
      <>
        You can email, call, message, or visit us at anytime during business
        hours. We will do everything in our power to resolve your issue in a
        timely manner.
      </>
    ),
  },
];

// PLANS
export const plansData = [
  {
    title: "Free",
    tag: "Starter",
    desc: "Not sure if you will like us? We offer a 2 week free trial",
    button: "Get Started",
    features: [
      "Test out different elements of our platform to see if it’s a good fit",
      "Free features",
    ],
  },
  {
    title: "Silver",
    desc: "For medium budget projects",
    button: "Begin Transformation",
    tag: "Recommended",
    features: [
      "Loans",
      "Savings",
      "Clients",
      "Accounting",
      "Reports",
      "Shares",
    ],
  },
  {
    title: "Gold",
    desc: "For high budget companies and enterprises",
    button: "Go to the Next Level",
    tag: "PRO",
    features: [
      "Loans",
      "Savings",
      "Clients",
      "Accounting",
      "Reports",
      "Shares",
      "SMS Banking",
      "Mobile Application",
      "Mobile Money Integration",
    ],
  },
];

// TEAM MEMBERS
const teamModel = (name, title, image) => {
  return { name: name, title: title, image: image };
};

export const teamMembers = [
  teamModel("Isaac Were", "Co-Founder and CEO", IsaacImg),
  teamModel("Sam Ellis", "CTO", SamImg),
  teamModel("Oshin Were", "Head of Business Development", OshinImg),
  teamModel("Shamilah Hatibu", "Operations Manager", ShamilahImg),
  teamModel("Brian Ssemate", "Lead Engineer", BrianImg),
  teamModel("Juliet Laker", "Administrator", JulietImg),
  teamModel("Doreen Kansiime", "Onboarding Specialist", DoreenImg),
];

// objectives
export const objectivesData = [
  {
    title: "Vision",
    desc: "To build a brighter and more inclusive tomorrow.",
    Icon: BrandSVG,
  },
  {
    title: "Mission",
    desc:
      "To bridge financial gaps in developing markets through innovation and technology.",
    Icon: MissionSVG,
  },
  {
    title: "Objectives",
    desc: (
      <>
        To cultivate economic growth and inclusion through financial
        infrastructure. <br />
        For every financial institution to have access to a one stop shop to
        success.
      </>
    ),
    Icon: DesignSVG,
  },
];

// features
export const featuresData = [
  {
    title: "Loans",
    desc:
      "Efficiently manage loan applications, seamlessly approve loans, promptly disburse funds, effortlessly track payments, and conveniently monitor collateral and guarantors, all within a single integrated platform.",
    Icon: CoinsSVG,
  },
  {
    title: "Savings",
    desc:
      "Effortlessly oversee all savers and their savings, streamline deposits and withdrawals, and efficiently handle any associated fees, ensuring a seamless management experience.",
    Icon: SaveSVG,
  },
  {
    title: "Clients",
    desc:
      "Effortlessly maintain comprehensive records of your clients' information, ranging from personal and business details to next of kin contacts. Seamlessly upload relevant files, such as passport photos and signatures, specific to each client for convenient access and organization.",
    Icon: UsersSVG,
  },
  {
    title: "Client Mobile App",
    desc:
      "Empower your members with our mobile app, enabling them to conveniently apply for loans, effortlessly access statements, conveniently make loan payments, and seamlessly manage deposits and withdrawals",
    Icon: SmartSVG,
  },

  {
    title: "SMS Notifications",
    desc:
      "Enhance member communication by leveraging our automated SMS messaging system, ensuring timely notifications are sent whenever transactions or other pertinent actions occur.",
    Icon: BellSVG,
  },
  {
    title: "Mobile Money",
    desc:
      "Embrace the convenience of a cashless society and seamlessly transact using mobile money. Effortlessly disburse loans and empower your members to make payments, deposits, and withdrawals, all through MTN’s and Airtel’s secure and efficient mobile money wallets.",
    Icon: CardSVG,
  },
  {
    title: "Customer Service/Support",
    desc:
      "We take immense pride in our product and value our clients, which is why we have assembled a dedicated team committed to delivering an exceptional experience for you.",
    Icon: HelpSVG,
  },
  {
    title: "Accounting",
    desc:
      "Effortlessly manage a wide range of financial as, including your chart of accounts, payables, receivables, staff till sheets, expenses, incomes, and much more.",
    Icon: AccountingSVG,
  },
  {
    title: "Reports",
    desc:
      "Gain access to a comprehensive suite of reports, ranging from income statements and balance sheets to arrears reports, aging reports, and much more.",
    Icon: StatsSVG,
  },
];

// testimonials
export const testimonialData = [
  {
    text:
      "I take immense pride in our product and value our clients, which is why we have assembled a dedicated team committed to delivering an exceptional experience for you.",
    name: "Brian Ssemate",
    occupation: "Engineer",
    image: BrianImg,
  },
  {
    text:
      "Gain access to a comprehensive suite of reports, ranging from income statements and balance sheets to arrears reports, aging reports, and much more.",
    name: "Isaac Were",
    occupation: "Co-Founder",
    image: IsaacImg,
  },
  {
    text:
      "I take immense pride in our product and value our clients, which is why we have assembled a dedicated team committed to delivering an exceptional experience for you.",
    name: "Brian Ssemate",
    occupation: "Engineer",
    image: BrianImg,
  },
  {
    text:
      "Gain access to a comprehensive suite of reports, ranging from income statements and balance sheets to arrears reports, aging reports, and much more.",
    name: "Isaac Were",
    occupation: "Co-Founder",
    image: IsaacImg,
  },
];

const clients = [
  {
    title: "Savings and Credit Cooperatives (SACCOs)",
    desc:
      "We help you manage your members along with their loans, savings, and more.",
  },
  {
    title: "Micro Finance Institutions (MFIs)",
    desc:
      "Whether you are a deposit taking MFI or not, our solutions can help you maximize your reach and operations.",
  },
  {
    title: "Other Lenders",
    desc:
      "If you are in the business of lending money, our solutions can help you as well.",
  },
];

// target clients
export const targetClientsData = {
  header: (
    <p>
      Zimba is the <b>Perfect</b> Solution
    </p>
  ),
  title: (
    <p>
      Our <HighlightSpan>Target</HighlightSpan> Clients
    </p>
  ),
  desc: (
    <div className="flexColumn gap20">
      {clients.map((client, i) => (
        <RowWrapper
          className="flex gap20"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay={i * 100 + 200}
        >
          <div>
            <CheckCircle sx={{ mt: 0.5 }} fontSize="small" color="primary" />
          </div>
          <div>
            <h2>{client.title}</h2>
            <br />
            <p>{client.desc}</p>
          </div>
        </RowWrapper>
      ))}
    </div>
  ),
  Image: <img src={Ills1} alt="clients" />,
};

export const financialData = [
  {
    title: "Financial Literacy",
    Icon: CreditScore,

    content: (
      <p>
        Financial literacy is crucial, as it equips individuals with the
        knowledge and understanding of key financial concepts. These concepts
        include budgeting, saving, investing, borrowing, and managing debt. It
        also encompasses interpreting financial statements, understanding tax
        laws, and making informed decisions regarding insurance and retirement
        planning.
        <br />
        <br />
        By possessing financial literacy skills, individuals can avoid financial
        pitfalls and achieve their goals. Budgeting and saving enable living
        within means and creating a financial safety net.
        <br />
        <br />
        Wise investing aids in wealth growth and retirement preparation.
        Effective debt management prevents cycles of debt and improves credit
        access.
      </p>
    ),
  },
  {
    title: "Digital Literacy",
    Icon: Devices,
    content: (
      <p>
        Digital literacy plays a crucial role in the realm of financial products
        in Uganda. With the rapid digitization of financial services, being
        digitally literate is essential for individuals to fully benefit from
        the convenience and accessibility offered by digital financial products.
        <br />
        <br />
        Understanding how to navigate digital platforms and securely engage with
        online transactions ensures that Ugandans can access a wide range of
        financial services, including banking, payments, loans, and investments.
        <br />
        <br />
        Digital literacy empowers individuals to make informed decisions, manage
        their finances effectively, and protect themselves from digital fraud
        and risks. By embracing digital literacy, Ugandans can confidently
        engage with financial products, expand their financial inclusion, and
        drive economic growth in the country.
      </p>
    ),
  },
];

// address Data
export const addressData = [
  {
    text: "ESERI, Plot 2893 Kayondo Road, Bbuye-Ntinda, Kampala (U)",
    icon: LocationOn,
  },
  {
    text: "Tel: +256 777 767 608",
    icon: LocalPhone,
  },
  {
    text: "info@zimba.io",
    icon: Mail,
  },
];

const paragraphStyle = {
  marginBottom: "1rem",
  fontSize: "14px",
  textAlign: "justify",
  color: "#09263B",
  fontFamily: "Open Sans sans-serif",
};

const listTitleStyle = {
  fontWeight: "bold",
  marginBottom: "0.5rem",
  fontSize: "1rem",
};

const listItemStyle = {
  marginBottom: "0.5rem",
  listStyleType: "disc",
  paddingLeft: "2rem",
};

export const PrivacyPolicyData = [
  {
    title: "Zimba Technologies Online Privacy Policy",
    Icon: PolicyIcon,

    content: (
      <>
        <p style={paragraphStyle}>
          We at Zimba Technologies Incorporated (hereinafter mentioned as
          "Zimba") are committed to protecting our customers’ personal
          information and strive to maintain the privacy of your Personal
          Information
        </p>
        <p style={paragraphStyle}>
          “Personal information” is any information that can be used by itself
          to uniquely identify, contact, or locate a person, or can be used with
          information available from other sources to uniquely identify an
          individual.
        </p>
        <p style={paragraphStyle}>
          Personal Information does not include information that is anonymous,
          aggregated or is no longer identifiable to a natural person and such
          information is classified as Non-Personal Information
        </p>
        <p style={paragraphStyle}>
          Personal information collected and/or held by us may include but is
          not limited to your name, father's name, mother's name, spouse's name,
          date of birth, current and previous addresses, telephone number,
          mobile phone number, email address, occupation, and information
          contained in the documents or images used as proof of identity and
          proof of address.
        </p>
        <p style={paragraphStyle}>
          For the purpose of this policy, sensitive personal data or information
          like financial information (such as bank account or credit card or
          debit card or other payment instrument details), passwords or
          authentication information for any of our products or services etc.
          has been considered as a part of Personal Information.
        </p>
        <p style={paragraphStyle}>
          Zimba may collect, store, process and transfer your Personal
          Information well in accordance with the applicable laws and
          regulations for a variety of regulatory and business purposes.
        </p>
        <h2 style={{ ...listTitleStyle }}>
          These may include, but are not limited to:
        </h2>
        <ul style={{ ...listItemStyle }}>
          <li style={paragraphStyle}>Verify your identity;</li>
          <li style={paragraphStyle}>
            Complete transactions effectively and bill for products and
            services;
          </li>
          <li style={paragraphStyle}>
            Respond to your request for service or assistance;
          </li>
          <li style={paragraphStyle}>
            Perform market analysis, market research, business and operational
            that help us get valuable insights to enhance customer experience as
            well as improve upon service delivery.
          </li>
          <li style={paragraphStyle}>
            Provide, maintain, personalize and improve our products and
            services;
          </li>
          <li style={paragraphStyle}>
            Research, develop and market new products and services
          </li>
          <li style={paragraphStyle}>
            Anticipate and resolve issues and concerns with our products and
            services;
          </li>
          <li style={paragraphStyle}>
            Promote and market products services and offers which we consider
            may be of interest to you and/or may benefit you; and
          </li>
          <li style={paragraphStyle}>
            Ensure adherence to legal and regulatory requirements for prevention
            and detection of frauds and crimes
          </li>
        </ul>
        <p style={paragraphStyle}>
          Non-personal information can be used to deliver based on your
          interest, for analyzing different trends and determining the
          performance of these services
        </p>
        <p style={paragraphStyle}>
          This Privacy Policy describes the Personal Information which we may
          collect and provides our approach towards handling and usage of or
          dealing with the same in compliance with the applicable laws and
          regulations
        </p>
        <p style={paragraphStyle}>
          Please read the terms of this Privacy Policy carefully. By using and
          continuing to use our products and services, you are deemed to have
          accepted and consented to the terms of this Policy
        </p>
        <p style={paragraphStyle}>
          Zimba reserves the right to amend or modify this Privacy Policy at any
          time, as and when the need arises. We request you to visit our website
          www.Zimba.in periodically for contemporary information and changes.
        </p>
      </>
    ),
  },
  {
    title: "Collection of Personal Information",
    Icon: Devices,
    content: (
      <>
        <p style={paragraphStyle}>
          We may collect your Personal Information when you use our services or
          otherwise interact with us during the course of our relationship. The
          Personal Information may be collected by Zimba through the Customer
          Application Form (CAF) or through its authorized third parties. Third
          Party is a service provider who associates with Zimba and is involved
          in handling, managing, storing, processing, protecting and
          transmitting information on behalf of Zimba. This definition also
          includes all sub-contractors, consultants and/or representatives of
          the Third party.
        </p>
        <p style={paragraphStyle}>
          We may also collect and hold information related to your utilization
          of our services which may include your call details, cell tower
          location information contact list, phone numbers, device details,
          IMSI, purchasing habits, IP Address your browsing history on our
          website or app, location details and additional information provided
          by you while using our services.
        </p>
        <p style={paragraphStyle}>
          We may keep a log of the activities performed by you on our network
          and websites by using various internet techniques such as web cookies,
          web beacons, server log files, etc. for analytical purposes and for
          analysis of the amiability of various features on our site. This
          information may be used to provide you with a better experience at our
          portal along with evidentiary purposes. At any time while you are
          browsing our site, if you do not wish to share browsing information,
          you may opt out of receiving the cookies from our site by making
          appropriate changes to your browser privacy settings. Please refer to
          Zimba’s Online Cookie Policy for more details.
        </p>
        <p style={paragraphStyle}>
          When you communicate with us, we may monitor or record our interaction
          to help us serve you better, to personalize your experiences, to train
          or otherwise support employees, resources or systems and for security
          purposes.
        </p>
        <p style={paragraphStyle}>
          We may obtain Personal Information from third parties including but
          not limited to credit information, demographic and interest data,
          contact, information from social media platforms when you interact
          with us via social media or use your social media login to interact
          with Zimba sites or offers, marketing lead and other information we
          may receive.
        </p>
        <p style={paragraphStyle}>
          In case you do not provide your information or consent for usage of
          Personal Information or later on withdraw your consent for usage of
          the personal information so collected, Zimba reserves the right to
          discontinue the services for which the said information was sought.
        </p>
      </>
    ),
  },
  {
    title: "Disclosure and Transfer of Personal Information",
    Icon: MoveUpIcon,

    content: (
      <>
        <p style={paragraphStyle}>
          Internal Use: As explained in the section “Collection of Personal
          Information” Zimba may utilize some or all available Personal
          Information for internal assessments, measures, operations and related
          activities or any other lawful purpose
        </p>
        <p style={paragraphStyle}>
          We may share your Personal Information for the purposes detailed in
          this Privacy Policy with our internal third parties, which are the
          other entities in our group of companies.
        </p>
        <p style={paragraphStyle}>
          We may transfer your personal information or other information
          collected, stored, processed by us to any other entity or organization
          located in Uganda or outside Uganda only in order to fulfil the terms
          of a contract we have with you or if you have consented (at the time
          of collection of information) to the same. This may also include
          sharing of aggregated information with them in order for them to
          understand our environment and consequently, provide you with better
          services. While sharing your Personal Information with third parties,
          adequate organizational, technical and security measures shall be
          taken to ensure that reasonable security practices are followed at the
          third party.
        </p>
        <p style={paragraphStyle}>
          If you wish to opt out of receiving product or service related
          communication from us, please email us at{" "}
          <a
            href="mailto:admin@zimba.io"
            style={{ color: "green", fontWeight: "bold" }}
          >
            admin@zimba.io
          </a>
        </p>
        <p style={paragraphStyle}>
          Government Agencies: We may also share your Personal Information
          without your consent with Government agencies or other authorized law
          enforcement agencies (LEAs) mandated under law to obtain such
          information for the purpose of verification of identity or for
          prevention, detection, investigation including but not limited to
          cyber incidents, prosecution, and punishment of offenses
        </p>
        <p style={paragraphStyle}>
          We may obtain your consent for sharing your Personal Information in
          several ways, such as in writing, online, through "click-through"
          agreements; orally, including through interactive voice response; or
          when your consent is part of the terms and conditions pursuant to
          which we provide you service. We, however, assure you that Zimba does
          not disclose your Personal Information to unaffiliated third parties
          (parties outside Zimba corporate network and its Strategic and
          Business Partners) which could lead to invasion of your privacy.
        </p>
        <p style={paragraphStyle}>
          Zimba may employ, contract or include third parties (as defined in
          section 1: Collection of Personal Information) external to itself for
          strategic, business, tactical and operational purposes. Such agencies,
          though external to Zimba, will always be entities which are covered by
          contractual agreements wherein we shall take reasonable measures to
          ensure that the authorized third parties adopt reasonable level of
          security practices and procedures to ensure security of Personal
          Information
        </p>
      </>
    ),
  },
  {
    title: "Security Practices and Procedures",
    Icon: SecurityIcon,

    content: (
      <>
        <p style={paragraphStyle}>
          We adopt reasonable security practices and procedures, in line with
          international standard IS/ISO/IEC 27001 and applicable laws, to
          include, technical, operational, managerial and physical security
          controls in order to protect your Personal Information from
          unauthorized access, or disclosure while it is under our control.
        </p>
        <p style={paragraphStyle}>
          We further take adequate steps to ensure that our third parties adopt
          reasonable levels of security practices and procedures to ensure
          security of Personal Information.
        </p>
        <p style={paragraphStyle}>
          While we observe reasonable security measures to protect your Personal
          Information on all our digital platforms and internet connections,
          security risks may still arise for reasons outside of our control such
          as hacking, virus disseminations, force majeure events, breaches of
          firewall and secure server software etc. Please note that the
          above-mentioned measures do not guarantee absolute protection to the
          Personal Information.
        </p>
        <p style={paragraphStyle}>
          For any responsible disclosure of a security vulnerability in our
          website, mobile application or our services, please send an email to{" "}
          <a
            href="mailto:security@zimba.io"
            style={{ color: "green", fontWeight: "bold" }}
          >
            security@zimba.io
          </a>
        </p>
      </>
    ),
  },
  {
    title: "Access and Correction",
    Icon: CollectionsIcon,

    content: (
      <>
        <p style={paragraphStyle}>
          To the extent feasible and subject to applicable laws we shall provide
          you access to the information that Zimba holds about you as well as
          facilitate any correction and updation of information. Zimba may need
          to request specific information from you to help us confirm your
          identity and ensure your right to access your personal data (or to
          exercise other rights you may have under applicable law). This is a
          security measure to ensure that personal data is not disclosed to any
          person who does not have a right to receive it. We may also contact
          you to ask you for further information in relation to your request to
          speed up our response. We use best efforts to respond to legitimate
          requests within a reasonable timeframe.
        </p>
        <p style={paragraphStyle}>
          If you no longer want to receive marketing messages from Zimba, you
          can choose to opt out at any time. If you’ve previously opted in to
          receive personalized content based on how and where you use our
          network, you can also opt out at any time The easiest way to opt out
          is by sending an email to{" "}
          <a
            href="mailto:admin@zimba.io"
            style={{ color: "green", fontWeight: "bold" }}
          >
            admin@zimba.io
          </a>
        </p>
        <p style={{ ...paragraphStyle, ...listTitleStyle }}>
          There are also various other ways to opt out:
        </p>
        <ul style={{ ...listItemStyle }}>
          <li style={paragraphStyle}>
            Call our customer services team at{" "}
            <span style={{ color: "green", fontWeight: "bold" }}>
              +256 763895805
            </span>{" "}
            or{" "}
            <span style={{ color: "green", fontWeight: "bold" }}>
              +256 708695675
            </span>
          </li>
          <li style={paragraphStyle}>
            Disable push notification messages, including marketing messages, at
            any time in our apps by changing the notification settings on your
            device or by uninstalling the app
          </li>
        </ul>
        <p style={paragraphStyle}>
          You may have received marketing from Zimba even if you’re not a
          customer or have never had contact with us. This is a result of
          third-party marketing lists which Zimba may acquire from time to time,
          stating that you have given permission to be contacted by other
          organizations. If you’ve registered with us to opt out of marketing
          from Zimba, you shouldn’t receive such communications. We reserve the
          right to modify this Privacy Notice. We will post any changes to our
          Privacy Notice on this page. Please check the “Last Updated” legend at
          the top of this page to see when this Privacy Notice was last revised.
          We encourage you to check frequently to see the current Privacy Notice
          to be informed of how Zimba is committed to protecting your
          information and providing you with improved content on our site in
          order to enhance your experience.
        </p>
      </>
    ),
  },
  {
    title: "Feedback and Concerns",
    Icon: FeedbackIcon,

    content: (
      <>
        <p style={paragraphStyle}>
          We are committed to safeguard your personal information collected and
          handled by us and look forward to your continued support for the same.
          In case of any feedback or concern regarding protection of your
          personal information, you can contact us at{" "}
          <a
            href="mailto:privacy@zimba.io"
            style={{ color: "green", fontWeight: "bold" }}
          >
            privacy@zimba.io
          </a>
        </p>
      </>
    ),
  },
];
