import {
  FacebookRounded,
  Instagram,
  LinkedIn,
  YouTube,
} from "@mui/icons-material";

export const ezyfiLink = "/ezifi";
export const financialLiteracyLink = "/financial-literacy";
export const contactLink = "/contact";
export const pricingLink = "/pricing";
export const PrivatePolicyLink = "/private-policy";

export const navLinks = [
  {
    label: "Home",
  },
  {
    label: "About",
  },
  {
    label: "Features",
  },
  {
    label: "Services",
    children: [
      {
        label: "EzyFi Banking Platform",
        to: ezyfiLink,
      },
      {
        label: "Financial Literacy",
        to: financialLiteracyLink,
      },
    ],
  },
  {
    label: "Pricing",
    to: pricingLink,
  },
  {
    label: "FAQs",
  },
  {
    label: "Privacy Policy",
    to: PrivatePolicyLink,
  },
];

export const socialLinks = [
  {
    Icon: FacebookRounded,
    link:
      "https://www.facebook.com/profile.php?id=100088691710191&mibextid=ZbWKwL",
  },
  {
    Icon: Instagram,
    link: "https://instagram.com/zimbatechnologies?igshid=ZDc4ODBmNjlmNQ==",
  },
  {
    Icon: LinkedIn,
    link: "https://www.linkedin.com/company/zimba-technologies/",
  },
  {
    Icon: YouTube,
    link: "#",
  },
];

export const locationLink =
  "https://www.google.com/maps/dir/0.323822,32.5712095/eseri+complex/@0.3447579,32.5570227,13z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x177dbbadc69adfbb:0xd076c9fc0b9df957!2m2!1d32.6143961!2d0.3580186?entry=ttu";
