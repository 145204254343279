import React from "react";
import { Landing } from "../pages/Landing";
import { Pricing } from "../pages/Pricing";
import { BankingPlatform } from "../pages/BankingPlatform";
import {
  contactLink,
  ezyfiLink,
  financialLiteracyLink,
  pricingLink,
  PrivatePolicyLink,
} from "./links";
import { FinancialLiteracy } from "../pages/FinancialLiteracy";
import { Contact } from "../sections/Contact";
import { PrivatePolicy } from "../pages/PrivatePolicy";

export const routes = [
  {
    path: "/",
    component: <Landing />,
  },
  {
    path: pricingLink,
    component: <Pricing />,
  },
  {
    path: contactLink,
    component: <Contact />,
  },
  {
    path: ezyfiLink,
    component: <BankingPlatform />,
  },
  {
    path: financialLiteracyLink,
    component: <FinancialLiteracy />,
  },
  {
    path: PrivatePolicyLink,
    component: <PrivatePolicy />,
  },
];
