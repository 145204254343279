import React from "react";
import styled from "styled-components";
import { GapWrapper, HighlightSpan, TitleP } from "../components/styled/Styled";
import { HeaderButton } from "../components/buttons/HeaderButton";
import { AccountantSVG } from "../assets/svg/Svgs";
import { financialData } from "../constants/siteData";
import { Divider } from "@mui/material";
import Footer from "../sections/Footer";
import { PatternStrip } from "../sections/PatternStrip";

export const HeaderSection = () => (
  <div className="poka-pattern">
    <Wrapper className="container flexSpaceCenter">
      <LeftSide className="flexColumn">
        <GapWrapper gap={10}>
          <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
            <h1 className="extraBold font60">
              Financial <HighlightSpan>Literacy</HighlightSpan>
            </h1>
          </div>
          <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
            <div className="font18 semiBold">
              Digital & Financial <b>Literacy</b> Services
            </div>
          </div>
        </GapWrapper>

        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="800">
          <TitleP className="font14">
            Zimba understands that having access to quality <b>core banking </b>
            software is just the beginning. We are committed to providing you
            and your clients with valuable information to enhance your
            institution's management and your clients' ability to save and repay
            loans effectively.
            <br />
            <br />
            Additionally, we prioritize digital literacy education to ensure
            your safety and success in an ever-growing digital world. To fulfill
            this commitment, we have partnered with <b>BeMoneyWiser</b> to offer
            complimentary financial literacy services exclusively for you.
          </TitleP>
        </div>

        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000">
          <HeaderButton link="https://bemoneywiser.com/">
            Visit BeMoneyWiser
          </HeaderButton>
        </div>
      </LeftSide>
      <RightSide>
        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000">
          <AccountantSVG />
        </div>
      </RightSide>
    </Wrapper>
  </div>
);

export const ContentSection = () => (
  <ContentWrapper>
    <div className="container">
      <GridWrapper>
        {financialData.map((val) => {
          const { title, Icon, content } = val;
          return (
            <CardWrapper className="radius8 borderBox">
              <h2 className="flexNullCenter">
                <Icon sx={{ mr: 3, fontSize: 30 }} color="primary" />
                {title}
              </h2>
              <Divider sx={{ my: 3 }} />
              <div className="font14"> {content}</div>
            </CardWrapper>
          );
        })}
      </GridWrapper>
    </div>
  </ContentWrapper>
);

export const FinancialLiteracy = () => {
  return (
    <>
      <HeaderSection />
      <ContentSection />
      <PatternStrip />
      <Footer />
    </>
  );
};
const Wrapper = styled.section`
  padding-top: 70px;
  width: 100%;
  min-height: 90vh;
  @media (max-width: 960px) {
    flex-direction: column;
    min-height: 100%;
  }
`;

const ContentWrapper = styled.section`
  padding: 70px 0;
  @media (max-width: 960px) {
    padding: 10px 0;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  gap: 40px;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;

const RightSide = styled.div`
  width: 50%;
  padding-left: 80px;
  @media (max-width: 960px) {
    width: 100%;
    padding: 30px;
  }
`;

const GridWrapper = styled.div`
  margin: 50px 0;
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 860px) {
    gap: 20px;
    grid-template-columns: 1fr;
  }
`;

const CardWrapper = styled.div`
  padding: 50px 50px;
  line-height: 1.5rem;
  @media (max-width: 860px) {
    padding: 50px 40px;
  }
`;
