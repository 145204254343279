import React from "react";
import styled from "styled-components";
import { GapWrapper, HighlightSpan, TitleP } from "../components/styled/Styled";
import { HeaderButton } from "../components/buttons/HeaderButton";
import { EzyfiLogo, ManageSVG } from "../assets/svg/Svgs";
import { contactLink } from "../constants/links";

export const Ezyfi = () => {
  return (
    <div className="poka-pattern">
      <Wrapper className="container flexSpaceCenter">
        <LeftSide className="flex flexColumn">
          <GapWrapper gap={10}>
            <div
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="1500"
            >
              <h1 className="extraBold font60">
                <HighlightSpan>EzyFi</HighlightSpan> Banking Platform
              </h1>
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="600"
            >
              <div className="font18 extraBold">
                Intuitive Loan Management System
              </div>
            </div>
          </GapWrapper>

          <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="800">
            <TitleP className="font14">
              Designed to save you time so you can focus on growth and your
              clients. The <b>Banking Platform</b> system allows to build
              customer journeys and tailor them according to your needs.
              <br />
              <br />
              We assist lenders in <b>automating</b> and streamlining entire
              loan life cycle including loan servicing, reporting, customer care
              and much more.
            </TitleP>
          </div>

          <div
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="1000"
          >
            <HeaderButton to={contactLink}>Start Today</HeaderButton>
          </div>
        </LeftSide>
        <RightSide>
          <div
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="1000"
          >
            <ManageSVG />
          </div>
        </RightSide>
      </Wrapper>
    </div>
  );
};
const Wrapper = styled.section`
  padding-top: 70px;
  width: 100%;
  min-height: 90vh;
  @media (max-width: 960px) {
    flex-direction: column;
    min-height: 100%;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  gap: 40px;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;

const RightSide = styled.div`
  width: 50%;
  padding: 30px;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
  }
`;
