import iIsaac from "./Isaac-Were.jpg";
import iDoreen from "./Doreen-Kansiime.jpg";
import iBrian from "./Brian-Ssemate.jpg";
import iJuliet from "./Juliet-Laker.jpg";
import iOshin from "./Oshin-Were.jpg";
import iShamilah from "./Shamilah-Hatibu.jpg";
import iSam from "./Sam-Ellis.jpg";

export const IsaacImg = iIsaac;
export const DoreenImg = iDoreen;
export const BrianImg = iBrian;
export const JulietImg = iJuliet;
export const OshinImg = iOshin;
export const ShamilahImg = iShamilah;
export const SamImg = iSam;
