import { Collapse } from "antd";
import React from "react";
import { faqData } from "../constants/siteData";
import { HeaderTitle, HighlightSpan } from "../components/styled/Styled";
import { QuestionAnswer } from "@mui/icons-material";
import { primaryColor } from "../components/styled/Theme";
import { Chip } from "@mui/material";
import { Link } from "react-router-dom";
import { contactLink } from "../constants/links";

export const Faqs = () => {
  return (
    <>
      <div id="faqs" className="container" style={{ paddingBlock: 80 }}>
        <div className="flexCenter wrap gap20">
          <div style={{ flex: 1, minWidth: 300 }}>
            <Chip
              label="Help & Support"
              sx={{ mb: 2, fontSize: 12 }}
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="1500"
            />
            <HeaderTitle>
              Frequently Asked <HighlightSpan>Questions</HighlightSpan>
              <QuestionAnswer sx={{ ml: 1, color: primaryColor }} />
            </HeaderTitle>

            <p
              className="font13"
              style={{ marginTop: 20, maxWidth: "80%", lineHeight: 1.8 }}
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="1500"
            >
              Got a question? We're here to answer! If you don't see your
              question here, drop us a line here on our{" "}
              <Link to={contactLink} className="highlightColor">
                Contact Page
              </Link>
              .
            </p>
          </div>
          <div style={{ flex: 2 }}>
            <Collapse accordion defaultActiveKey={0} items={faqData} />
          </div>
        </div>
      </div>
    </>
  );
};
