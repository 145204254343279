import styled from "styled-components";
import { RowWrapper, Wrapper } from "../components/styled/Styled";
import { IconButton } from "@mui/material";
import {
  contactLink,
  ezyfiLink,
  financialLiteracyLink,
  pricingLink,
  socialLinks,
} from "../constants/links";
import { AccessAlarm, CalendarMonth, Chat } from "@mui/icons-material";
import { LogoLight } from "../assets/svg/Svgs";
import { addressData } from "../constants/siteData";

export const getCurrentYear = () => {
  return new Date().getFullYear();
};

const ContactItem = ({ text, Icon }) => {
  return (
    <div
      className="whiteColor font12 flexNullCenter gap10"
      style={{ paddingBottom: "10px" }}
    >
      <Icon fontSize="small" />
      <StyleP2>{text}</StyleP2>
    </div>
  );
};

const LinkItem = ({ children, to }) => (
  <div style={{ paddingBottom: 5 }}>
    <a
      className="whiteColor animate pointer font13 lightLink"
      href={to}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  </div>
);

export default function Footer() {
  return (
    <Wrapper className="darkGreenBg poka-pattern-dark">
      <div className="container">
        <InnerWrapper className="flexSpaceNull">
          {/* Logo */}
          <SectionWrapper className="flexCenter">
            <LogoLight width={200} />
          </SectionWrapper>

          {/* Contact */}
          <SectionWrapper>
            <Title>Contact Us</Title>

            {addressData.map((val, i) => (
              <ContactItem key={i} text={val.text} Icon={val.icon} />
            ))}
          </SectionWrapper>

          {/* Working */}
          <SectionWrapper>
            <Title>Working Hours</Title>
            <ContactItem text="Monday - Friday" Icon={CalendarMonth} />
            <ContactItem text="8:00 hrs - 17:00 hrs" Icon={AccessAlarm} />
            <ContactItem text="24/7 Online Support" Icon={Chat} />
          </SectionWrapper>

          {/* Products */}
          <SectionWrapper>
            <Title>Quick Links</Title>
            <LinkItem to={ezyfiLink}>Banking Platform</LinkItem>
            <LinkItem to={financialLiteracyLink}>Financial Literacy</LinkItem>
            <LinkItem to={pricingLink}>Pricing Plans</LinkItem>
            <LinkItem to={contactLink}>Contact Us</LinkItem>
          </SectionWrapper>
        </InnerWrapper>
      </div>

      {/* Bottom section */}
      <div
        className="flex"
        style={{
          borderTop: "1px solid rgb(255, 255, 255, 0.1)",
          backdropFilter: "blur(5px)",
        }}
      >
        <RowWrapper
          className="flexSpaceCenter container"
          style={{ padding: 20 }}
        >
          <StyleP className="whiteColor font12">
            © 2022 - {getCurrentYear()} | Zimba Technologies. All Rights
            Reserved.
          </StyleP>

          <StyleP>
            {socialLinks.map(({ Icon, link }, i) => (
              <IconButton key={i}>
                <a href={link} className="animate whiteColor flex lightLink">
                  <Icon />
                </a>
              </IconButton>
            ))}
          </StyleP>
        </RowWrapper>
      </div>
    </Wrapper>
  );
}

const Title = styled.h4`
  padding-bottom: 30px;
  color: white;
`;

const InnerWrapper = styled.div`
  padding: 70px 0;
  @media (max-width: 550px) {
    padding: 50px 0;
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
    width: 100%;
    text-align: center;
  }
`;

const StyleP2 = styled.p`
  max-width: 300px;
`;

const SectionWrapper = styled.div`
  width: 20%;
  @media (max-width: 550px) {
    width: 100%;
    padding: 20px;
  }
`;
